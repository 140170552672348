.tvi-logo {
  &.fr:before {
    content: "\e90a";
  }
  &.pt:before {
    content: "\e900";
  }
  &.nl:before {
    content: "\e901";
  }
  &.es:before {
    content: "\e902";
  }
  &.de:before {
    content: "\e904";
  }
  &:not(.fr):not(.pt):not(.nl):not(.es):not(.de):before {
    content: "\e903";
  }
}

.tvi-logo-mobile {
  &.fr:before {
    content: "\e90b";
  }
  &.pt:before {
    content: "\e905";
  }
  &.nl:before {
    content: "\e909";
  }
  &.es:before {
    content: "\e908";
  }
  &.de:before {
    content: "\e906";
  }
  &:not(.fr):not(.pt):not(.nl):not(.es):not(.de):before {
    content: "\e907";
  }
}