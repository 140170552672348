.w-20px {
  width: 20px;
}
.w-25px {
  width: 25px;
}
.w-30px {
  width: 30px;
}
.w-40px {
  width: 40px;
}
.w-45px {
  width: 45px;
}
.w-50px {
  width: 50px;
}
.w-100px {
  width: 100px;
}

.w-35px {
  width: 35px;
}

.w-45 {
  width: 45%;
}

.w-80 {
  width: 80%;
}

.mw-75px {
  max-width: 75px;
}

.mw-100px {
  max-width: 100px;
}

.mw-50 {
  max-width: 50%;
}

.mw-75 {
  max-width: 75%;
}

.mw-40 {
  max-width: 40%;
}

.h-20px {
  height: 20px;
}
.h-25px {
  height: 25px;
}
.h-30px {
  height: 30px;
}
.h-35px {
  height: 35px;
}
.h-40px {
  height: 40px;
}
.h-45px {
  height: 45px;
}
.h-50px {
  height: 50px;
}
.h-75px {
  height: 75px;
}
.h-90px {
  height: 90px;
}
.h-100px {
  height: 100px;
}

.h-300px {
  height: 300px;
}

.h-90 {
  height: 90%;
}

.h-80vh {
  height: 80vh;
}

.h-75vh {
  height: 75vh;
}

.h-4-rem {
  height: 4rem;
}

.lh-20px {
  line-height: 20px;
}
.lh-45px {
  line-height: 45px;
}

.mh-35px {
  max-height: 35px;
}

.mh-150px {
  max-height: 150px;
}

.min-h-100 {
  min-height: 100%;
}


@media (min-width: $break-sm) {
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }

  .w-sm-20px {
    width: 20px;
  }
  .w-sm-25px {
    width: 25px;
  }
  .w-sm-30px {
    width: 30px;
  }
  .w-sm-45px {
    width: 45px;
  }
  .w-sm-50px {
    width: 50px;
  }

  .h-sm-20px {
    height: 20px;
  }
  .h-sm-25px {
    height: 25px;
  }
  .h-sm-30px {
    height: 30px;
  }
  .h-sm-45px {
    height: 45px;
  }
  .h-sm-50px {
    height: 50px;
  }
}

@media (min-width: $break-md) {
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }

  .w-md-20px {
    width: 20px;
  }
  .w-md-25px {
    width: 25px;
  }
  .w-md-30px {
    width: 30px;
  }
  .w-md-45px {
    width: 45px;
  }
  .w-md-50px {
    width: 50px;
  }

  .h-md-20px {
    height: 20px;
  }
  .h-md-25px {
    height: 25px;
  }
  .h-md-30px {
    height: 30px;
  }
  .h-md-45px {
    height: 45px;
  }
  .h-md-50px {
    height: 50px;
  }
}

@media (min-width: $break-lg) {
  .w-lg-20 {
    width: 20% !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-20px {
    width: 20px;
  }
  .w-lg-25px {
    width: 25px;
  }
  .w-lg-30px {
    width: 30px;
  }
  .w-lg-45px {
    width: 45px;
  }
  .w-lg-50px {
    width: 50px;
  }
  .w-lg-500px {
    width: 500px !important;
  }
  .w-lg-650px {
    width: 650px !important;
  }

  .h-lg-20px {
    height: 20px;
  }
  .h-lg-25px {
    height: 25px;
  }
  .h-lg-30px {
    height: 30px;
  }
  .h-lg-45px {
    height: 45px;
  }
  .h-lg-50px {
    height: 50px;
  }
  .h-lg-6rem {
    height: 6rem;
  }

  .mh-lg-50vh {
    max-height: 50vh;
  }
}

@media (min-width: $break-xl) {
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }

  .w-xl-20px {
    width: 20px;
  }
  .w-xl-25px {
    width: 25px;
  }
  .w-xl-30px {
    width: 30px;
  }
  .w-xl-45px {
    width: 45px;
  }
  .w-xl-50px {
    width: 50px;
  }

  .h-xl-20px {
    height: 20px;
  }
  .h-xl-25px {
    height: 25px;
  }
  .h-xl-30px {
    height: 30px;
  }
  .h-xl-45px {
    height: 45px;
  }
  .h-xl-50px {
    height: 50px;
  }
}

@media (min-width: $break-xxl) {
  .w-xxl-25 {
    width: 25% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-75 {
    width: 75% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }

  .w-xxl-20px {
    width: 20px;
  }
  .w-xxl-25px {
    width: 25px;
  }
  .w-xxl-30px {
    width: 30px;
  }
  .w-xxl-45px {
    width: 45px;
  }
  .w-xxl-50px {
    width: 50px;
  }

  .h-xxl-20px {
    height: 20px;
  }
  .h-xxl-25px {
    height: 25px;
  }
  .h-xxl-30px {
    height: 30px;
  }
  .h-xxl-45px {
    height: 45px;
  }
  .h-xxl-50px {
    height: 50px;
  }
}