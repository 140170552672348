@import "text-size-vars";

//PIXELS
.text-size-8 {
  font-size: 8px !important;
}
.text-size-10 {
  font-size: 10px !important;
}
.text-size-11 {
  font-size: 11px !important;
}
.text-size-12 {
  font-size: 12px !important;
}
.text-size-13 {
  font-size: 13px !important;
}
.text-size-14 {
  font-size: 14px !important;
}
.text-size-15 {
  font-size: 15px !important;
}
.text-size-18 {
  font-size: 18px !important;
}
.text-size-20 {
  font-size: 20px !important;
}

.text-size-22 {
  font-size: 22px !important;
}

.text-size-23 {
  font-size: 23px !important;
}
.text-size-25 {
  font-size: 25px !important;
}
.text-size-30 {
  font-size: 30px !important;
}
.text-size-40 {
  font-size: 40px !important;
}
.text-size-45 {
  font-size: 45px !important;
}
.text-size-50 {
  font-size: 50px !important;
}

/* MD */
@media (min-width: $break-md) {
  .text-size-md-8 {
    font-size: 8px !important;
  }
  .text-size-md-10 {
    font-size: 10px !important;
  }
  .text-size-md-11 {
    font-size: 11px !important;
  }
  .text-size-md-12 {
    font-size: 12px !important;
  }
  .text-size-md-13 {
    font-size: 13px !important;
  }
  .text-size-md-15 {
    font-size: 15px !important;
  }
  .text-size-md-18 {
    font-size: 18px !important;
  }
  .text-size-md-20 {
    font-size: 20px !important;
  }
  .text-size-md-25 {
    font-size: 25px !important;
  }
  .text-size-md-30 {
    font-size: 30px !important;
  }
  .text-size-md-35 {
    font-size: 35px !important;
  }
  .text-size-md-40 {
    font-size: 40px !important;
  }
  .text-size-md-45 {
    font-size: 45px !important;
  }
  .text-size-md-50 {
    font-size: 50px !important;
  }
}

/* LG */
@media (min-width: $break-lg) {
  .text-size-lg-8 {
    font-size: 8px !important;
  }
  .text-size-lg-10 {
    font-size: 10px !important;
  }
  .text-size-lg-11 {
    font-size: 11px !important;
  }
  .text-size-lg-12 {
    font-size: 12px !important;
  }
  .text-size-lg-13 {
    font-size: 13px !important;
  }
  .text-size-lg-15 {
    font-size: 15px !important;
  }
  .text-size-lg-18 {
    font-size: 18px !important;
  }
  .text-size-lg-20 {
    font-size: 20px !important;
  }
  .text-size-lg-25 {
    font-size: 25px !important;
  }
  .text-size-lg-30 {
    font-size: 30px !important;
  }
  .text-size-lg-40 {
    font-size: 40px !important;
  }
  .text-size-lg-45 {
    font-size: 45px !important;
  }
  .text-size-lg-50 {
    font-size: 50px !important;
  }
}

/* XL */
@media (min-width: $break-xl) {
  .text-size-xl-8 {
    font-size: 8px !important;
  }
  .text-size-xl-10 {
    font-size: 10px !important;
  }
  .text-size-xl-11 {
    font-size: 11px !important;
  }
  .text-size-xl-12 {
    font-size: 12px !important;
  }
  .text-size-xl-13 {
    font-size: 13px !important;
  }
  .text-size-xl-15 {
    font-size: 15px !important;
  }
  .text-size-xl-18 {
    font-size: 18px !important;
  }
  .text-size-xl-20 {
    font-size: 20px !important;
  }
  .text-size-xl-25 {
    font-size: 25px !important;
  }
  .text-size-xl-30 {
    font-size: 30px !important;
  }
  .text-size-xl-40 {
    font-size: 40px !important;
  }
  .text-size-xl-45 {
    font-size: 45px !important;
  }
  .text-size-xl-50 {
    font-size: 50px !important;
  }
}

/* XXL */

.small-text, .small-text * {
  font-weight: 400;
  color: #b2b2b2;
  b {
    font-weight : 700 !important;
  }
  font-size: 11px;
  @media (min-width: $break-md) {
    font-size: 12px;
  }
}

.text-extra-small {
  font-size: $extra-small-text-size;
  @media (min-width: $break-lg) {
    font-size: $extra-small-lg-text-size;
  }
}

.text-small {
  font-size: $small-text-size;
  @media (min-width: $break-lg) {
    font-size: $small-lg-text-size;
  }
}

.text-big {
  font-size: $big-text-size;
  @media (min-width: $break-lg) {
    font-size: $big-lg-text-size;
  }
}

.text-extra-big {
  font-size: $extra-big-text-size;
  @media (min-width: $break-lg) {
    font-size: $extra-big-lg-text-size;
  }
}

.small-lg-text {
  @media (min-width: $break-lg) {
    font-size: $small-lg-text-size;
  }
}