@import "../responsive-vars";
@import "../z-index-vars";

.searcher {
  .dropdown {
    background-color: #E7E7E7;
    width: 30%;
  }

  .btn-search {
    background-color: #fbbc04;
  }

  input {
    padding: 0.555rem 0.75rem;
  }

  @media (min-width: $break-lg) {
    &:hover {
      input, button {
        border-color: #fbbc04 !important;
      }
    }
  }
}