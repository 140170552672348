.quantity-selector {
  .quantity {
    width: 50px !important;

    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,&::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &.disabled, &.disabled input {
    background-color: $color-gray-light !important;
  }
}
