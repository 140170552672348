@import "../responsive-vars";
@import "colors-vars";

.text-inherit {
  color: inherit!important;
}
.bg-inherit {
  background-color: inherit!important;
}
.border-inherit {
  border-color: inherit!important;
}
.text-purple {
  color: $color-purple !important;
}
.bg-purple {
  background-color: $color-purple !important;
}
.border-purple {
  border-color: $color-purple !important;
}
.text-purple-medium-light {
  color: $color-purple-medium-light !important;
}
.bg-purple-medium-light {
  background-color: $color-purple-medium-light !important;
}
.border-purple-medium-light {
  border-color: $color-purple-medium-light !important;
}
.text-purple-light {
  color: $color-purple-light !important;
}
.bg-purple-light {
  background-color: $color-purple-light !important;
}
.border-purple-light {
  border-color: $color-purple-light !important;
}
.text-gray {
  color: $color-gray !important;
}
.bg-gray {
  background-color: $color-gray !important;
}
.border-gray {
  border-color: $color-gray !important;
}
.text-gray-light {
  color: $color-gray-light !important;
}
.bg-gray-light {
  background-color: $color-gray-light !important;
}
.border-gray-light {
  border-color: $color-gray-light !important;
}
.text-gray-super-light {
  color: $color-gray-super-light !important;
}
.bg-gray-super-light {
  background-color: $color-gray-super-light !important;
}
.border-gray-super-light {
  border-color: $color-gray-super-light !important;
}
.text-gray-dark {
  color: $color-gray-dark !important;
}
.bg-gray-dark {
  background-color: $color-gray-dark !important;
}
.border-gray-dark {
  border-color: $color-gray-dark !important;
}
.text-blue {
  color: $color-blue !important;
}
.bg-blue {
  background-color: $color-blue !important;
}
.border-blue {
  border-color: $color-blue !important;
}
.text-yellow {
  color: $color-yellow !important;
}
.bg-yellow {
  background-color: $color-yellow !important;
}
.border-yellow {
  border-color: $color-yellow !important;
}
.text-red {
  color: $color-red !important;
}
.bg-red {
  background-color: $color-red !important;
}
.border-red {
  border-color: $color-red !important;
}
.text-green {
  color: $color-green !important;
}
.bg-green {
  background-color: $color-green !important;
}
.border-green {
  border-color: $color-green !important;
}
.text-gold {
  color: $color-gold!important;;
}
.bg-gold {
  background-color: $color-gold!important;
}
.border-gold {
  border-color: $color-gold!important;
}
.border-white {
  border-color: $color-white!important;
}

.bg-black-footer {
  background-color: $color-black-footer!important;
}

.bg-light-blue {
  background-color: $light-blue-color!important;
}
.text-light-blue {
  color: $light-blue-color!important;
}

.bg-lighter-blue {
  background-color: $lighter-blue-color!important;
}

@media (min-width: $break-lg) {
  .bg-lg-none {
    background: none !important;
  }

  .text-lg-dark {
    color: #212529 !important;
  }
}

.bg-mint-green {
  background-color: $mint-green-color !important;
}

.color-inherit {
  color: inherit !important;
}

.bg-alt-blue {
  background-color: $alt-blue-color !important;
}

.bg-last-units {
  background-color: $last-units-color !important;
}

.bg-pink {
  background-color: $pink-color !important;
}

.bg-message-sos {
  background-color: $massage-sos-color !important;
}

.text-message-sos {
  color: $message-sos-text-color !important;
}

.text-not-found {
  color: $not-found-color !important;
}
.bg-not-found {
  background-color: $not-found-bg-color !important;
}

.bg-newsletter {
  background-color: $newsletter-gb-color !important;
}

.bg-newsletter-voucher {
  background-color: $newsletter-voucher-bg-color !important;
}

.bg-gray-lighter {
  background-color: $gray-lighter-color !important;
}

.text-facebook {
  color: $facebook-color !important;
}

.bg-facebook {
  background-color: $facebook-color !important;
}

.bg-personalized-service-option {
  background-color: $personalized-service-option-color !important;
}

.bg-error {
  background-color: $alert-error-bg !important;
}

.text-error {
  color: $alert-text-color !important;
}

.text-interest {
  color: $user-interests-color-text !important;
}

.bg-paginator-mobile {
  background-color: $mobile-paginator-color;
}


.bg-gold-premium {
  background-color: $color-gold-premium!important;
}