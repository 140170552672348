@import "icons-sizes-vars";

.icon-small {
  font-size: $icon-small;
}

.icon-medium {
  font-size: $icon-medium;
}

.icon-big {
  font-size: $icon-big;

  @media (min-width: $break-lg) {
    font-size: $icon-lg-big;
  }
}

.icon-extra-big {
  font-size: $icon-extra-big;

  @media (min-width: $break-lg) {
    font-size: $icon-lg-extra-big;
  }
}

.old-website .icon {
  font-size: inherit;
}