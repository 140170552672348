.tvi {
  &.tvi-kids {
    &:before {
      content: "\e900";
    }
  }
  &.tvi-tshirt-palette {
    &:before {
      content: "\e901";
    }
  }
  &.tvi-free-shipping {
    &:before {
      content: "\e902";
    }
  }
  &.tvi-product-finder {
    &:before {
      content: "\e903";
    }
  }
  &.tvi-palette {
    &:before {
      content: "\e904";
    }
  }
  &.tvi-text-font {
    &:before {
      content: "\e905";
    }
  }
  &.tvi-edit {
    &:before {
      content: "\e906";
    }
  }
  &.tvi-swap-horizontal {
    &:before {
      content: "\e907";
    }
  }
  &.tvi-return {
    &:before {
      content: "\e908";
    }
  }
  &.tvi-blind-drop-old {
    &:before {
      content: "\e909";
    }
  }
  &.tvi-blind-mechanism {
    &:before {
      content: "\e90a";
    }
  }
  &.tvi-blind-fabric {
    &:before {
      content: "\e90b";
    }
  }
  &.tvi-ar-icon {
    &:before {
      content: "\e90c";
    }
  }
  &.tvi-add-logo {
    &:before {
      content: "\e90d";
    }
  }
  &.tvi-text-icon {
    &:before {
      content: "\e90e";
    }
  }
  &.tvi-avatar {
    &:before {
      content: "\e90f";
    }
  }
  &.tvi-avatar-bold {
    &:before {
      content: "\e910";
    }
  }
  &.tvi-avatar-gallery {
    &:before {
      content: "\e911";
    }
  }
  &.tvi-avatar-selfie {
    &:before {
      content: "\e912";
    }
  }
  &.tvi-plus {
    &:before {
      content: "\e913";
    }
  }
  &.tvi-shopping-cart {
    &:before {
      content: "\e914";
    }
  }
  &.tvi-globe {
    &:before {
      content: "\e915";
    }
  }
  &.tvi-user {
    &:before {
      content: "\e916";
    }
  }
  &.tvi-chevron-left {
    &:before {
      content: "\e917";
    }
  }
  &.tvi-chevron-down {
    &:before {
      content: "\e918";
    }
  }
  &.tvi-chevron-right {
    &:before {
      content: "\e919";
    }
  }
  &.tvi-chevron-up {
    &:before {
      content: "\e91a";
    }
  }
  &.tvi-times {
    &:before {
      content: "\e91b";
    }
  }
  &.tvi-bars {
    &:before {
      content: "\e91c";
    }
  }

  &.tvi-info-circle {
    &:before {
      content: "\e91d";
    }
  }

  &.tvi-image {
    &:before {
      content: "\e91e";
    }
  }

  &.tvi-cube {
    &:before {
      content: "\e91f";
    }
  }

  &.tvi-copy {
    &:before {
      content: "\e920";
    }
  }

  &.tvi-check-solid {
    &:before {
      content: "\e921";
    }
  }

  &.tvi-home-delivery {
    &:before {
      content: "\e922";
    }
  }

  &.tvi-envelope {
    &:before {
      content: "\e923";
    }
  }

  &.tvi-parcel-shop {
    &:before {
      content: "\e924";
    }
  }

  &.tvi-pickup {
    &:before {
      content: "\e925";
    }
  }

  &.tvi-ruler {
    &:before {
      content: "\e926";
    }
  }

  &.tvi-image-regular {
    &:before {
      content: "\e90e";
    }
  }

  &.tvi-text-image-regular {
    &:before {
      content: "\e927";
    }
  }

  &.tvi-text-regular {
    &:before {
      content: "\e928";
    }
  }


  &.tvi-palette {
    &:before {
      content: "\e929";
    }
  }


  &.tvi-packs {
    &:before {
      content: "\e92a";
    }
  }

  &.tvi-shopping-cart-plus {
    &:before {
      content: "\e92b";
    }
  }

  &.tvi-blind-drop {
    &:before {
      content: "\e92c";
    }
  }

  &.tvi-blind-chain {
    &:before {
      content: "\e92d";
    }
  }

  &.tvi-blind-tissue {
    &:before {
      content: "\e92e";
    }
  }

  &.tvi-model {
    &:before {
      content: "\e92f";
    }
  }

  &.tvi-office {
    &:before {
      content: "\e930";
    }
  }

  &.tvi-present {
    &:before {
      content: "\e931";
    }
  }

  &.tvi-shopping-bag {
    &:before {
      content: "\e932";
    }
  }

  &.tvi-wall {
    &:before {
      content: "\e933";
    }
  }

  &.tvi-window {
    &:before {
      content: "\e934";
    }
  }

  &.tvi-floor {
    &:before {
      content: "\e935";
    }
  }

  &.tvi-briefcase {
    &:before {
      content: "\e936";
    }
  }

  &.tvi-wallpaper-size-3m {
    &:before {
      content: "\e937";
    }
  }

  &.tvi-wallpaper-size-98ft {
    &:before {
      content: "\e938";
    }
  }

  &.tvi-wallpaper-size-10m {
    &:before {
      content: "\e940";
    }
  }

  &.tvi-wallpaper-size-327ft {
    &:before {
      content: "\e941";
    }
  }

  &.tvi-bar-grafic {
    &:before {
      content: "\e939";
    }
  }

  &.tvi-anuncios {
    &:before {
      content: "\e93a";
    }
  }

  &.tvi-experiencia {
    &:before {
      content: "\e93b";
    }
  }

  &.tvi-analytics-graf {
    &:before {
      content: "\e93c";
    }
  }

  &.tvi-magnifying-glass {
    &:before {
      content: "\e93d";
    }
  }

  &.tvi-blind-support-color {
    &:before {
      content: "\e93e";
    }
  }

  &.tvi-car {
    &:before {
      content: "\e93f";
    }
  }

  &.tvi-frame {
    &:before {
      content: "\e942";
    }
  }

  &.tvi-canvas-material {
    &:before {
      content: "\e943";
    }
  }

  &.tvi-tenstickers-premium {
    &:before {
      content: "\e944";
    }
  }

  &.tvi-tenviniler-premium {
    &:before {
      content: "\e945";
    }
  }
  
  &.tvi-voucher {
  &:before {
    content: "\e946"; 
  }
}
}
