@import "../z-index-vars";

.z-index-inherit {
  z-index: inherit !important;
}

.z-index-min {
  z-index: $z-index-min;
}

.z-index-lower {
  z-index: $z-index-lower;
}

.z-index-low {
  z-index: $z-index-low;
}

.z-index-medium {
  z-index: $z-index-medium;
}