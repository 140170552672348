@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
.border-md-top {

  @media (min-width: $break-sm) {
    /*TABLET*/
    border-top: 1px solid $border-color !important;
  }
}

@media (min-width: $break-lg) {
  .border-lg-0 {
    border: 0 !important;
  }
  .border-lg-1 {
    border-width: 1px !important;
  }
  .border-lg-2 {
    border-width: 2px !important;
  }
  .border-lg-3 {
    border-width: 3px !important;
  }
  .border-lg-4 {
    border-width: 4px !important;
  }

  .border-lg-top {
    @media (min-width: $break-lg) {
      border-top: 1px solid $border-color !important;
    }
  }
}

.border-purple {
  border-color: $color-purple!important;
}