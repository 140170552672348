@import "icons";

//Done using https://icomoon.io/app/

@font-face {
  font-family: 'tenvinilo-logo';
  src:  url('fonts/tenvinilo-logo.eot?jk0gz2');
  src:  url('fonts/tenvinilo-logo.eot?jk0gz2#iefix') format('embedded-opentype'),
  url('fonts/tenvinilo-logo.ttf?jk0gz2') format('truetype'),
  url('fonts/tenvinilo-logo.woff?jk0gz2') format('woff'),
  url('fonts/tenvinilo-logo.svg?jk0gz2#tenvinilo-logo') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.tvi-logo, .tvi-logo-mobile {
  font-family: 'tenvinilo-logo' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}