@import "font";
@import "responsive-vars";
@import "Tools/text-sizes";
@import "Tools/text-colors";
@import "Tools/z-index";
@import "Tools/colors";
@import "Tools/check-inputs";
@import "z-index-vars";
@import "Tools/breadcrumb";
@import "Icons/font";
@import "Logo/font";
@import "Tools/sizes";
@import "Tools/overflow";
@import "Tools/buttons";
@import "Tools/radiobuttons";
@import "Tools/inputs";
@import "Tools/cursor";
@import "Tools/scrollbar";
@import "Tools/quantity-selector";
@import "Tools/border";
@import "Product/color-picker";
@import "Tools/flex";
@import "Tools/text-size-vars";
@import "Tools/icons";
@import "Tools/grid";
@import "Cookies/cookies-message-critic";
@import "Tools/textarea";
@import "Tools/aspect-ratio";

body {
  font-family: 'Open Sans', sans-serif !important;
  overflow-x: hidden;
  font-size: $default-text-size;

  @media (min-width: $break-lg) {
    font-size: $default-lg-text-size;
  }

  .old-website {
    font-size: var(--bs-body-font-size);
  }
}

.hidden {
  display: none;
}

h1 {
  line-height: 1.5 !important;
}