@import "../z-index-vars";
@import "../responsive-vars";
@import "../Tools/colors-vars";
@import "searcher-critic";
@import "../LeftMenu/main-critic";
@import "vars";

header {
  -webkit-transition: top 0.5s;
  -moz-transition: top 0.5s;
  -ms-transition: top 0.5s;
  -o-transition: top 0.5s;
  transition: top 0.5s;

  .navbar-content {
    height: 70px;

    @media (min-width: $break-lg) {
      height: 110px;
    }
  }

  .dh-bg-aux {
    background: linear-gradient(90deg, #FFF, #FFF 50%, $gray-lighter-color 50%, $gray-lighter-color);
    z-index: -1;
  }
  .dh-bg {
    background: linear-gradient(45deg, #FFF, #FFF 80px, $gray-lighter-color 80px, $gray-lighter-color);
  }
  .dh-row {
    padding-left: 110px;
  }

  .logo {
    width: 120px;
    max-height: 100%;

    @media (min-width: $break-lg) {
      width: 200px;
      height: 50px;

      &.es {
        width: 160px;
      }
    }
  }

  .user {
    width: 160px;

    span {
      max-width: 120px;
    }
  }

  &.header-min {
    top: -70px;
  }

  .open-cart {
    .items {
      background-color: $color-purple;
    }
  }
}

.header-messages {
  height: 50px;
  @media (min-width: $break-lg) {
    height: 40px;
  }
}

.header-searcher {
  height: 70px;
  width: calc(100% + (var(--bs-gutter-x) * .5)*2);
  margin: 0 calc(var(--bs-gutter-x) * -.5);
  @media (min-width: $break-lg) {
    height: auto;
    width: 100%;
    margin: 0;
  }

  .search-form {
    height: 50px;

    input {
      border-bottom-left-radius: 50px;
      border-top-left-radius: 50px;
      padding-left: 25px;

      &::placeholder {
        color: black;
      }
    }
    button[type="submit"] {
      border-bottom-right-radius: 50px;
      border-top-right-radius: 50px;
      padding-right: 25px;
    }
  }
}

#header {
  #header-top, #header-middle {
    max-height: 1000px;
    transition: max-height 0.2s;
  }

  #header-top {
    height: 40px;
    &.bg-dark {
      background-color: #232323;
    }
  }

  #header-middle {
    height: 77px;

    @media (min-width: $break-lg) {
      height: 83px;
    }

    .mobile-logo {
      width: 40px;
      height: 40px;
      @media (max-width: 320px) {
        width: 33px;
        height: 33px;
      }
    }

    .fa-shopping-cart {
      @media (max-width: $break-lg) {
        color: $old-gray-color;
      }
    }

    .open-cart {
      .items {
        @media (max-width: $break-lg) {
          background-color: #d00;
        }

        @media (min-width: $break-lg) {
          background-color: #3399ff;
        }
      }
    }
  }

  .border-bottom-animate {
    &::after {
      content: '';
      border-bottom: 2px solid white;
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      -webkit-transition: 0.25s ease;
      -moz-transition: 0.25s ease;
      -ms-transition: 0.25s ease;
      -o-transition: 0.25s ease;
      transition: 0.25s ease;
    }

    &:hover::after {
      left: 0;
      width: 100%;
    }
  }

  .border-gray {
    border-color: $old-gray-color !important;
  }

  #header-middle {
    .fa-shopping-cart {
      @media (max-width: $break-lg) {
        color: $old-gray-color;
      }
    }

    .open-cart {
      .items {
        @media (max-width: $break-lg) {
          background-color: #d00;
        }

        @media (min-width: $break-lg) {
          background-color: #3399ff;
        }
      }
    }
  }

  #header-bottom {
    height: 33px;

    .splide {
      .splide__arrows  button:disabled {
        display: none !important;
      }

      .highlight {
        background-color: #d8b545;
        text-transform: uppercase;
      }

      .splide__slide {
        &::after {
          content: '';
          border-bottom: 3px solid black;
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 0;
          -webkit-transition: 0.25s ease;
          -moz-transition: 0.25s ease;
          -ms-transition: 0.25s ease;
          -o-transition: 0.25s ease;
          transition: 0.25s ease;
        }

        @media (min-width: $break-lg) {
          &:hover::after {
            left: 0;
            width: 100%;
          }
        }

        &.selected::after {
          left: 0;
          width: 100%;
        }
      }

    }

    .open-cart {
      .items {
        @media (max-width: $break-lg) {
          background-color: #d00;
        }

        @media (min-width: $break-lg) {
          background-color: #3399ff;
        }
      }
    }
  }
}

body.old-header {
  padding-top: $header-height-xs;

  @media (min-width: $break-sm) {
    padding-top: $header-height-sm;
  }
  @media (min-width: $break-md) {
    padding-top: $header-height-md;
  }
  @media (min-width: $break-lg) {
    padding-top: $header-height-lg;
  }
  @media (min-width: $break-xl) {
    padding-top: $header-height-xl;
  }
  @media (min-width: $break-xxl) {
    padding-top: $header-height-xxl;
  }
}