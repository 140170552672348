.widget-help {
  padding-top: 20px;
  label {
    box-shadow: 0 0 .5rem .3rem rgba(0,0,0,.15)!important;
    padding: 12px;
  }

  .principal {
    width: 80px;
  }

  .secondary {
    width: 35px;
    left: 69%;
    top: -10%;
  }
}