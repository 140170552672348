#breadcrumb {
  ul {
    list-style: none;

    li {
      &:not(:first-child):not(:last-child)::after {
        content: "Â»";
        padding: 0 3px;
      }

      a:hover span {
        color: $breadcrumb-active-color !important;
      }

      &.current {
        font-style: italic;
      }
    }
  }
}