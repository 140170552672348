.color-picker {
  position: relative;
  display: inline-block;
  margin: 0 4px;

  input {
    display: none;

    &:checked + label {
      border-width: 3px;
      color: #000;

      i {
        display: inline-block;
      }
    }
    &:disabled + label {
      display: none;
    }
  }
  label {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid;

    i {
      display: none;
    }
  }
}

.preview-color {
  display: inline-block;
  width: 14px;
  height: 14px;
  border: 1px solid;
  border-radius: 50%;
}