.radio-small {
  font-size: 13px;
}

.form-check-big {
  padding-left: calc(1.5em + 7px);

  .form-check-input {
    font-size: 20px;
    border-width: 2px;
  }
}

.btn-check-group {
  display: flex;

  .btn-check {
    +.btn-check-dark {
      border: 1px solid $color-gray-light;
      transition: border .3s;
      font-weight: bold;
      &:hover{
        border-color: $color-gray;
      }
    }

    &:checked {
      +.btn-check-dark {
        border-color: $color-black!important;
        z-index: 1;
      }
    }
  }

  >.btn:not(:first-of-type) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;
  }
  >.btn:not(:last-of-type) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
