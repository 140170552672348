@import "icons";

//Done using https://icomoon.io/app/

$font-family: "tenvinilo" !default;
$font-path: "fonts" !default;

@font-face {
  font-family: '#{$font-family}';
  src:  url('#{$font-path}/#{$font-family}.eot?q2bnxqq');
  src:  url('#{$font-path}/#{$font-family}.eot?q2bnxqq#iefix') format('embedded-opentype'),
  url('#{$font-path}/#{$font-family}.ttf?q2bnxqq') format('truetype'),
  url('#{$font-path}/#{$font-family}.woff?q2bnxqq') format('woff'),
  url('#{$font-path}/#{$font-family}.svg?q2bnxq##{$font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.tvi {
  font-family: '#{$font-family}' !important;
  display: inline-block;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
