@import "../Tools/colors-vars";
@import "../Tools/buttons-sizes-vars";

.btn-info {
  background-color: $btn-info-color !important;
  color: rgba(255,255,255,.84) !important;
}

.btn-purple {
  background-color: $color-purple !important;
  color: #FFF !important;

  &.disabled {
    background-color: $color-gray!important;
  }
}

.btn-dark.disabled {
  background-color: $color-gray!important;
}

.btn-green {
  background-color: $color-green !important;
  color: #FFF !important;

  &.disabled {
    background-color: $color-gray!important;
  }
}

.btn-outline-green {
  color: $color-green!important;
  border-color: $color-green;

  @media (hover: hover) {
    &:hover {
      background-color: $color-green !important;
      color: #FFF !important;
    }
  }
}

.btn-outline-purple {
  color: $color-purple!important;
  border-color: $color-purple;

  @media (hover: hover) {
    &:hover {
      background-color: $color-purple !important;
      color: #FFF !important;
    }
  }
}

.button {
  font-size: inherit;

  &:focus {
    box-shadow: none;
  }

  .direction-icon {
    transition-duration: .2s;
  }
}

.btn-square-small {
  padding: 0;
  width: $button-square-small;
  height: $button-square-small;
  &>* {
    vertical-align: middle!important;
  }
}

.btn-square-medium {
  padding: 0;
  width: $button-square-medium;
  height: $button-square-medium;
  &>* {
    vertical-align: middle!important;
  }
}

.btn-square-big {
  padding: 0;
  width: $button-square-big;
  height: $button-square-big;
  &>* {
    vertical-align: middle!important;
  }

  @media (min-width: $break-lg) {
    width: $button-square-lg-big;
    height: $button-square-lg-big;
  }
}

.btn-shadow {
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.20);
}
.btn-news-padding {
  padding: 10px 30px;
}