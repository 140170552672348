.pointer {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed;
}

.zoom-in {
  cursor: zoom-in;
}