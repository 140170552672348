.check-input-dark {
  border-color: #212529 !important;
  color: white !important;

  &[type='checkbox'] {
    box-shadow: none !important;
    border-width: 2px !important;
  }

  &:checked {
    background-color: #212529 !important;
  }
}

.check-input-purple {
  &:checked {
    background-color: $color-purple;
    border-color: $color-purple;
  }
}

.check-input-secondary {
  border-color: $old-gray-color !important;
  color: white !important;
  box-shadow: none !important;
  border-width: 2px !important;

  &:checked {
    background-color: $old-gray-color !important;
  }
}

.check-input-green {
  border-color: rgba(0, 0, 0, .25) !important;
  color: white !important;

  &[type='checkbox'] {
    box-shadow: none !important;
    border-width: 2px !important;
  }

  &:checked {
    background-color: $color-green !important;
  }
}

.check-input-transparent {
  background-color: transparent !important;
  border-color: white !important;
}

.check-input-bold:checked {
  +label {
    font-weight: bold;
  }
}